<template>
  <v-container fluid>
    <v-card raised>
      <v-card-title class="bg-clr">
        <v-btn fab flat @click.native="goback">
          <v-icon class="pointer" v-ripple>arrow_back</v-icon>
        </v-btn>
        <span class="headline">Add Milestone</span>
      </v-card-title>
      <v-card-text class="content-border">
        <v-form ref="form">
          <div class="heading primary-clr">Countries</div>
          <v-layout row>
            <v-flex md5>
              <v-select
                label="Preferred Countries"
                :items="workingCountries"
                multiple
                class="pt-0"
                style="padding-top: 12px !important"
                v-model="countrySortName"
                :rules="[rules.required]"
                single-line
              />
            </v-flex>
          </v-layout>
          <hr class="divider-rule" />
          <div class="heading primary-clr">Location Details</div>
          <v-layout row>
            <v-flex md5>
              <v-text-field
                v-model="pickup"
                prepend-icon="place"
                placeholder="Pick Up Location"
                :rules="[rules.required]"
              ></v-text-field>
            </v-flex>
            <v-flex md5 class="addMilestone">
              <v-text-field
                v-model="dropoff"
                prepend-icon="place"
                placeholder="Drop Off Location"
                :rules="[rules.required]"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <hr class="divider-rule" />
          <div class="heading primary-clr">Checklist Details</div>
          <v-layout row>
            <v-flex md5>
              <v-icon
                color="orange darken-1"
                class="white--text add-icon"
                flat
                @click.native="addChecklist()"
                >add</v-icon
              >
              <v-text-field
                :key="i"
                v-for="(checklist, i) in checklistField"
                v-model="checklist.name"
                placeholder="Check List Name"
                class="text-field-check"
                :rules="[rules.required]"
                append-icon="close"
                @click:append="removeCheckList(i)"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <hr class="divider-rule" />

          <v-layout row>
            <v-btn
              color="orange darken-1"
              style="color: white"
              @click.prevent="checkAdd()"
              :loading="loading"
              >Submit</v-btn
            >
          </v-layout>
        </v-form>
        <v-snackbar
          :timeout="3000"
          bottom
          color="red darken-2"
          v-model="x.error"
          class="white--text"
          v-if="x.error"
          >{{ x.error }}</v-snackbar
        >
        <success-dialog
          :content="x.message"
          :show="x.success"
          :onclose="closeMessage"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { StorageKeys } from "../../constants/constants";
import ErrorBox from "@/components/Common/ErrorBox";
import SuccessDialog from "@/components/Common/SuccessDialog";
import { addNewMilestone } from "../../constants/api-urls";
export default {
  created() {},
  data() {
    return {
      document: [],
      pickup: "",
      dropoff: "",
      countrySortName: [],
      res: "",
      checklistField: [
        {
          i: 0,
          name: "",
        },
        {
          i: 1,
          name: "",
        },
        {
          i: 2,
          name: "",
        },
      ],
      dialog: false,
      message: null,
      loading: false,
      processing: false,
      x: {
        error: "",
        message: "",
        success: false,
      },
      rules: {
        required: (value) => !!value || "This field is required.",
      },
    };
  },
  components: {
    ErrorBox,
    SuccessDialog,
  },
  methods: {
    async checkAdd() {
      this.operationName = "add-milestones";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.addMilestone();
      } else {
        return;
      }
    },
    closeMessage() {
      this.pickup = "";
      this.dropoff = "";
      this.checklistField = [
        {
          i: 0,
          name: "",
        },
      ];
      this.x.success = false;
    },
    validateCheckList(check) {
      for (var i = 0; check.length > i; i++) {
        if (check[i].hasOwnProperty("name") && !check[i]["name"].trim()) {
          return false;
        } else {
        }
      }
      return true;
    },
    addMilestone() {
      if (!navigator.onLine) {
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return;
      }
      if (this.processing == true) {
        return;
      } else {
        if (
          !this.checklistField ||
          !this.pickup.trim() ||
          !this.dropoff.trim() ||
          !this.countrySortName.length
        ) {
          this.x.error = "Please fill all Fields";
        } else {
          if (!this.validateCheckList(this.checklistField)) {
            this.x.error = "Please fill minimum of 3 checklist.";
          } else {
            this.processing = true;
            let url = addNewMilestone;
            delete this.axios.defaults.headers.common["token"];
            let token = localStorage.getItem(StorageKeys.SessionKey);
            let config = {
              headers: {
                authorization: `bearer ${token}`,
              },
            };

            let checkList = [];
            this.checklistField.forEach((element) => {
              delete element["i"];
              checkList.push(element);
            });
            let body = {
              pickupLocation: this.pickup.trim(),
              dropOffLocation: this.dropoff.trim(),
              checkList,
              countrySortName: this.countrySortName,
            };
            this.axios.post(this.constants.apiUrl + url, body, config).then(
              (response) => {
                this.x.success = true;
                this.x.message = response.data.message;
                this.$router.push("/milestone");
                this.processing = false;
              },
              (error) => {
                this.processing = false;
                this.x.error = "Failed to Add Milestone";
              }
            );
          }
        }
      }
    },
    addChecklist() {
      let el = this.checklistField.length;
      let checklist = {
        i: this.checklistField[el - 1] + 1,
        name: null,
      };
      this.checklistField.push(checklist);
    },
    removeCheckList(i) {
      if (this.checklistField.length == 1) {
        this.x.error = "At Least One Value need to be Present";
      } else {
        let el = this.checklistField;
        el.splice(i, 1);
        this.checklistField = el;
      }
    },
    goback() {
      this.$router.go(-1);
    },
  },
  computed: {
    workingCountries: function () {
      if (localStorage.getItem("workingCountries") !== null) {
        return JSON.parse(localStorage.workingCountries).map((m) => ({
          text: m.value,
          value: m.value,
        }));
      }
      return [];
    },
  },
};
</script>
<style scoped>
.milestone {
  background: #ffffff;
}
.title-layout-size {
  padding: 3px 16px !important;
}
.message {
  display: inline-block;
  margin: 0 50px;
}
.communication {
  width: 40%;
}
.success {
  font-size: 18px;
  font-weight: 600;
}
.messagebtn {
  margin: 0 auto;
}
.list {
  display: block;
}
.addMilestone {
  margin: 0 auto;
}
.add-icon {
  border: 2px solid darkorange;
  border-radius: 50%;
  margin: 25px 10px 20px 0px;
  float: left;
  cursor: pointer;
}
.text-field-check:not(:first-child) {
  float: right;
  width: 90%;
}
</style>
